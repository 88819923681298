<template>
	<MhView :isLoading="isLoading">

		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>	

		<div class="filterPosts">
			<div class="filterPosts__row border border--bottom row">
				<div class="filterPosts__col filterPosts__col--key hSpace hSpace--big vSpace vSpace--default col col--2of12">
					<span class="font font--uppercase font--sizeSmall">Filter</span>				
				</div>			
				<div class="filterPosts__col filterPosts__col--values vSpace vSpace--default col col--9of12">
					<span class="font font--uppercase font--sizeSmall">
						<a class="filterPosts__toggleFilterBtn noArrow" :class="{'filterPosts__toggleFilterBtn--isActive' : showSemesterFilterRow}" @click="toggleFilterRow('semesters')">{{$root.getTranslatedValue('Semester')}}</a>
						<a class="filterPosts__toggleFilterBtn noArrow" :class="{'filterPosts__toggleFilterBtn--isActive' : showCategoryFilterRow}" @click="toggleFilterRow('categories')">{{$root.getTranslatedValue('Modul')}}</a>
						<a class="filterPosts__toggleFilterBtn noArrow" :class="{'filterPosts__toggleFilterBtn--isActive' : showThemeFilterRow}" @click="toggleFilterRow('themes')">{{$root.getTranslatedValue('Thema')}}</a>
					</span>				
				</div>			
				<div class="filterPosts__col filterPosts__col--spacer hSpace vSpace vSpace--default vSpace--notBottom col col--1of12">
				</div>
			</div>
			
			<div v-show-slide="showSemesterFilterRow">				
				<div class="filterPosts__row filterPosts__row--filter filterPosts__row--semesterFilter border border--bottom row">
					<div class="filterPosts__col filterPosts__col--key hSpace hSpace--big vSpace vSpace--default filterPosts__col--first col col--2of12">
						<span class="font font--uppercase font--sizeSmall">{{$root.getTranslatedValue('Semester')}}</span>				
					</div>
					<div class="filterPosts__col filterPosts__col--values vSpace vSpace--default vSpace--notBottom  col col--9of12">
						<div class="filterPosts__links font font--sizeSmall" v-if="semesterFilterPosts.length">						
							<a 	class="filterPosts__link noArrow"
								:class="{'filterPosts__link--isActive' : !filterGroupHasActiveFilter( semesterFilterPosts ) }"
								@click="clickAll( 'semesters' )">
								{{$root.getTranslatedValue('Alle')}}
							</a>
							<a 	class="filterPosts__link noArrow" 
								:class="{'filterPosts__link--isActive' : filterPost.status}"
								@click="clickFilter( filterPost, 'semesters' )" 
								v-for="filterPost in semesterFilterPosts" 
								:data-id="filterPost.id"
								:key="filterPost.id">
								{{$root.getTranslatedAcfValue( filterPost.acf, 'postTitle')}}
							</a>
						</div>
						<!--
						<pre>{{semesterFilterPosts.length}}</pre>
						-->
					</div>
					<div class="filterPosts__col filterPosts__col--spacer hSpace vSpace vSpace--default vSpace--notBottom  col col--1of12">
						<a class="filterPosts__closeFilterRowBtn noArrow" @click="closeFilterRow( 'semesters' )">×</a>
					</div>
				</div>
			</div>
			
			<div v-show-slide="showCategoryFilterRow">	
				<div class="filterPosts__row filterPosts__row--filter filterPosts__row--categoriesFilter border border--bottom row">
					<div class="filterPosts__col filterPosts__col--key hSpace hSpace--big vSpace vSpace--default filterPosts__col--first col col--2of12">
						<span class="font font--uppercase font--sizeSmall">{{$root.getTranslatedValue('Modul')}}</span>
					</div>
					<div class="filterPosts__col filterPosts__col--values hSpace vSpace vSpace--default vSpace--notBottom col col--9of12">
						<div class="filterPosts__links font font--sizeSmall" v-if="categoryFilterPosts.length">						
							<a 	class="filterPosts__link noArrow" 
								:class="{'filterPosts__link--isActive' : !filterGroupHasActiveFilter( categoryFilterPosts ) }"
								@click="clickAll( 'categories' )">
								{{$root.getTranslatedValue('Alle')}}
							</a>
							<a 	class="filterPosts__link noArrow" 
								:class="{'filterPosts__link--isActive' : filterPost.status}"
								@click="clickFilter( filterPost, 'categories' )" 
								v-for="filterPost in categoryFilterPosts" 
								:data-id="filterPost.id"
								:key="filterPost.id">
								{{$root.getTranslatedAcfValue( filterPost.acf, 'postTitle')}}
							</a>
						</div>
						<!--
						<pre>{{categoryFilterPosts.length}}</pre>
						-->				
					</div>				
					<div class="filterPosts__col filterPosts__col--spacer hSpace vSpace vSpace--default vSpace--notBottom  col col--1of12">
						<a class="filterPosts__closeFilterRowBtn noArrow" @click="closeFilterRow( 'categories' )">×</a>
					</div>
				</div>
			</div>
			
			<div v-show-slide="showThemeFilterRow">	
				<div class="filterPosts__row filterPosts__row--filter filterPosts__row--themesFilter border border--bottom row">
					<div class="filterPosts__col filterPosts__col--key hSpace hSpace--big vSpace vSpace--default filterPosts__col--first col col--2of12">
						<span class="font font--uppercase font--sizeSmall">{{$root.getTranslatedValue('Thema')}}</span>
					</div>
					<div class="filterPosts__col filterPosts__col--values hSpace vSpace vSpace--default vSpace--notBottom col col--9of12">
						<div class="filterPosts__links font font--sizeSmall" v-if="themeFilterPosts.length">						
							<a	class="filterPosts__link noArrow" 
								:class="{'filterPosts__link--isActive' : !filterGroupHasActiveFilter( themeFilterPosts ) }"
								@click="clickAll( 'themes' )">
								{{$root.getTranslatedValue('Alle')}}
							</a>
							<a 	class="filterPosts__link noArrow" 
								:class="{'filterPosts__link--isActive' : filterPost.status}"
								@click="clickFilter( filterPost , 'themes' )" 
								v-for="filterPost in themeFilterPosts" 
								:data-id="filterPost.id"
								:key="filterPost.id">
								{{$root.getTranslatedAcfValue( filterPost.acf, 'postTitle')}}
							</a>
						</div>
						<!--
						<pre>{{categoryFilterPosts.length}}</pre>
						-->
					</div>					
					<div class="filterPosts__col filterPosts__col--spacer hSpace vSpace vSpace--default vSpace--notBottom  col col--1of12">
						<a class="filterPosts__closeFilterRowBtn noArrow" @click="closeFilterRow( 'themes' )">×</a>
					</div>
				</div>
			</div>
		</div>

		<div class="posts noisy">		
			
			<!-- desktop: masonry -->
			<template v-if="!$root.isSmallDevice">			
				<div 	class="masonry"
						v-masonry 
						origin-left="true" 
						:transition-duration="masonryTransitionDuration" 
						item-selector=".masonry__item"
						column-width=".masonry__sizer">
					
					<div 	v-masonry-tile class="masonry__sizer"></div>
					<div 	v-masonry-tile class="masonry__item posts__post" 
							v-for="post in filteredPosts" 
							:data-width="post.acf.width" 
							:key="post.id">
						
						<MhImage class="posts__postImage" :imageObject="post.acf.image"></MhImage>
						
						<div class="hSpace hSpace--small vSpace vSpace--postTeaser">
							<h2>{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}</h2>
							<span class="font font--sizeSmall">
								<template v-if="getFilterPostById(post.acf.category)">
									{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.category).acf, 'postTitle')}} ·
								</template>
								<template v-if="getFilterPostById(post.acf.semester)">
									{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}}
								</template>
							</span>
						</div>

						<router-link class="posts__postLink noArrow" :to="$root.getSingleLink( post )"></router-link>
						
					</div>			
				
				</div>
			</template>
			<!-- mobile: alles untereinander -->
			<template v-else>
				<div 	class="posts__post" 
						v-for="post in filteredPosts" 
						:key="post.id">
					
					<MhImage class="posts__postImage" :imageObject="post.acf.image"></MhImage>
					
					<div class="hSpace hSpace--small vSpace vSpace--postTeaser">
						<h2>{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}</h2>
						<span class="font font--sizeSmall">
							<template v-if="getFilterPostById(post.acf.category)">
								{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.category).acf, 'postTitle')}} ·
							</template>
							<template v-if="getFilterPostById(post.acf.semester)">
								{{$root.getTranslatedAcfValue( getFilterPostById(post.acf.semester).acf, 'postTitle')}}
							</template>
						</span>
					</div>
					
					<router-link class="posts__postLink noArrow" :to="$root.getSingleLink(post)"></router-link>
					
				</div>
			</template>
			
			<div	class="row row--red posts__borderBelow border border--top"></div>
			
		</div>
	
	</MhView>
</template>

<script>
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'ProjectsView',
		components: {
			MhView,
			MhImage,
			MobilePageTitle,
		},		
		mixins: [ RestHandler ],
		data() {
			return {
				isLoading: true,
				posts: [],
				filterPosts: [],
				showSemesterFilterRow: false,
				showCategoryFilterRow: false,
				showThemeFilterRow: false,
				masonryTransitionDuration: 0,
			}
		},
		computed: {				
			semesterFilterPosts() {
				if( this.filterPosts ){
					return this._.filter( this.filterPosts, (post) => { 
						return post.acf.type.slug == 'semester'
					})
				}
			},	
			categoryFilterPosts() {
				if( this.filterPosts ){
					return this._.filter( this.filterPosts, (post) => { 
						return post.acf.type.slug == 'category'
					})
				}
			},	
			themeFilterPosts() {
				if( this.filterPosts ){
					return this._.filter( this.filterPosts, (post) => { 
						return post.acf.type.slug == 'theme'
					})
				}
			},	
			filteredPosts() {
				let returnPosts = []
				
				// sammle die aktiven filter
				const activeSemesters = this._.map( 
					this._.filter( this.semesterFilterPosts, (post) => { 
						return post.status === true 
					} ), 'id' )				
				const activeCategories = this._.map( 
					this._.filter( this.categoryFilterPosts, (post) => { 
						return post.status === true 
					} ), 'id' )				
				const activeThemes = this._.map( 
					this._.filter( this.themeFilterPosts, (post) => { 
						return post.status === true 
					} ), 'id' )	
				const activeFilter = this._.concat( activeSemesters, activeCategories, activeThemes)
				
				//console.log('activeFilter: ' + activeFilter)
				
				// checke die aktiven filter und befülle dann das return-array
				this._.forEach( this.posts, (post) => {
					
					let postSemester = post.acf.semester
					let postCategory = post.acf.category
					let postTheme    = post.acf.theme
					
					if( activeFilter.length ){
						
						if( this._.indexOf(activeFilter, postSemester) > -1 ) returnPosts.push( post )							
						if( this._.indexOf(activeFilter, postCategory) > -1 ) returnPosts.push( post )
						if( this._.indexOf(activeFilter, postTheme) > -1 ) returnPosts.push( post )
						
					}else{
						
						returnPosts.push( post )
						
					}
					
					/*					
					// wenn ein semester-filter gesetzt wurde
					if( activeSemesters.length ){
						if( this._.indexOf(activeSemesters, postSemester) > -1 ){
							returnPosts.push( post )							
						}
					}
					// wenn ein category-filter gesetzt wurde
					else if( activeCategories.length ){
						if( this._.indexOf(activeCategories, postCategory) > -1 ){
							returnPosts.push( post )							
						}
					}
					// wenn ein theme-filter gesetzt wurde
					else if( activeThemes.length ){
						if( this._.indexOf(activeThemes, postTheme) > -1 ){
							returnPosts.push( post )							
						}
					}
					// wenn kein filter gesetzt wurde
					else{
						returnPosts.push( post )
					}			
					
					*/
					
				} ) 
				
				return this._.uniq(returnPosts)
			},
		},
		methods: {
			closeFilterRow( filterType ) {
				if( 'semesters' == filterType ){
					this.showSemesterFilterRow = false
					this.semesterFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'categories' == filterType ){
					this.showCategoryFilterRow = false
					this.categoryFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'themes' == filterType ){
					this.showThemeFilterRow = false
					this.themeFilterPosts.forEach( (post) => { post.status = false });
				}
			},
			toggleFilterRow( filterType ){												
				if( 'semesters' == filterType ){
					this.showSemesterFilterRow = !this.showSemesterFilterRow
					this.semesterFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'categories' == filterType ){
					this.showCategoryFilterRow = !this.showCategoryFilterRow
					this.categoryFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'themes' == filterType ){
					this.showThemeFilterRow = !this.showThemeFilterRow
					this.themeFilterPosts.forEach( (post) => { post.status = false });
				}
			},
			clickFilter( filterPost, filterType ){				
				// wunsch von ies, dass immer nur ein filter pro type aktiv sein kann
				this.clickAll( filterType )
				
				filterPost.status = !filterPost.status
			},
			clickAll( filterType ) {
				if( 'semesters' == filterType ){
					this.semesterFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'categories' == filterType ){
					this.categoryFilterPosts.forEach( (post) => { post.status = false });
				}
				if( 'themes' == filterType ){
					this.themeFilterPosts.forEach( (post) => { post.status = false });
				}					
			},
			filterGroupHasActiveFilter( filterPosts ) {
				if( filterPosts ){
					const activeFilter = this._.filter(filterPosts, (post) => { return post.status===true } )
					if( activeFilter && activeFilter.length ) return true
				}
			},
			getFilterPostById( id ) {
				const filterPosts = this._.concat( this.semesterFilterPosts, this.categoryFilterPosts, this.themeFilterPosts )
				return this._.findLast(filterPosts, {id})
			},			
			getUrlParams( search ) {
				let hashes = search.slice(search.indexOf('?') + 1).split('&')
				return hashes.reduce((params, hash) => {
					let [key, val] = hash.split('=')
					return Object.assign(params, {[key]: decodeURIComponent(val)})
				}, {})
			},
		},
		mounted() {
			
			// fetch all related filterPosts
			this.restHandler__fetch({
				action : 'GET',				
				route : '/wp-json/mh/v1/usedFilters',
				params : {
					postType : this.$route.meta.postType,
				},
				callback : (response) => {
					let results = response.data.result
					
					results.forEach( (post) => { post.status = false }); // set status to false, it's used as active state
					
					this.filterPosts = results
					this.$redrawVueMasonry() // relayout to make shure the boxes have the right height
				}
			})
			
			// fetch all posts
			this.restHandler__fetchAllPaginatedPosts({
				action : 'GET',				
				route : '/wp-json/mh/v1/posts',
				params : {
					postType : this.$route.meta.postType,
					perPage : 50,
				},
				callback : (results) => {
					this.posts = results
					this.isLoading = false
					this.masonryTransitionDuration = "0.35s"
				}				
			})

		},	
	}
</script>

<style lang="less" scoped>	
	@import "../less/includes/grid.include.less";
	
	.masonry {
		//background-color: fade(yellow, 25);
		max-width: 100vw;
		overflow: hidden;
		min-height: inherit;
	}
	.masonry__sizer,
	.masonry__item {
		width: 12.5%;
		width: 25%;
	}
	.masonry__item[data-width="2"] {
		width: 50%;
	}
	.masonry__item[data-width="3"] {
		width: 75%;
	}
	.masonry__item[data-width="4"] {
		width: 100%;
	}
	
	@filter__marginRight: 2em;	
	.filterPosts {
		position: relative;
		z-index: 2; // render on top to keep the border-bottom
		
		&__toggleFilterBtn {
			padding-left: 0;
			margin: 0;
			margin-right: @filter__marginRight;
			color: inherit;
			text-decoration: none;
			cursor: pointer;
			white-space: nowrap;
			
			&:hover {
				text-decoration: underline;
			}
			&:before {
				position: relative;
				display: inline-block;
				width: 0.75em;
				content: "+";
				transform: translateY(-0.1em);
			}
		}
		&__toggleFilterBtn--isActive {
			&:before {
				content: "×";
			}
		}
		&__closeFilterRowBtn {
			@btnWidth: 2em;
			
			//background-color: red;
			display: block;
			width: @btnWidth;
			margin-left: calc(100% - @btnWidth);
		}
	}
	.filterPosts__links {
		display: flex;
		flex-wrap: wrap;
		padding-right: 12.5%;
		padding-top: 0.3em;		
	}
	.filterPosts__link {
		//outline: 1px solid red;
		margin-right: @filter__marginRight;
		//width: 15%;
		display: flex;
		padding-bottom: 1.22em;
		&:before {
			content: ""; padding: 0;
		}		
	}
	.filterPosts__link--isActive {
		text-decoration: underline;
	}

	.posts {
		min-height: calc(100vh + 0.5px);
		transform: translateY(-1px);
		flex-grow: 1;
		// da die posts alle 0.5px nach oben versetzt sind (damit es keine doppelten borders gibt)
		// muss hier der nachfolgende content nachgezogen werden
		margin-bottom: -.5px; 
	}
	.posts__borderBelow {
		height: 4px; 
		//background-color: fade(red, 20);
		
		// um die line zwischen posts und dem footer zu erzeugen 
		// wird das element gebraucht. die halben pixel werte entstehen
		// durch das versetzen der posts um einen halben pixel (siehe .posts)
		//margin-top: -1.5px; < war zu viel
		margin-top: -.5px; // 2018-10-23  das passt
		margin-bottom: -2px;
	}
	
	.posts__post {
		//outline: 1px solid red;		
		position: relative;
		background-color: white;
		//width: 25%;			
		
		// border around
		&:after {
			position: absolute;
			top: -0.5px; left: -0.5px;
			bottom: -0.5px; right: -0.5px;
			border: 1px solid black;
			// wunsch von ies, keine untere linie damit die lücken nicht so sichtbar sind
			// border-bottom: 1px solid transparent; 
			// 2019-02-18  neuer wunsch, untere linie soll wieder dazu			
			content: "";
		}
	}
	.posts__postText {
		padding-top: 0.65em;
		padding-bottom: 2.5em;
	}
	.posts__postLink {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		z-index: 10;
		
		&:before, &:after {
			content: "";
		}
	}

	@media @mediaQuery__sm {
		.filterPosts__col--key {
			width: 6em;
		}
		.filterPosts__col--values {
			width: calc(100% - 9em);
		}
		.filterPosts__col--spacer {
			width: 3em;
		}
		
		.filterPosts__toggleFilterBtn {
			margin-right: 1em;
		}
		.filterPosts__links {
			padding-right: 1em;
			margin-top: 0.1em;
		}
		.filterPosts__link {
			padding-right: 1em;
			width: 100%;
		}
		
		.posts__post::after {
			border-left: none;
			border-right: none;
		}
	}
</style>
